// @flow

import { locale } from '../lib/utils';

export type SurveyAction = {
  type: string,
  value: string | null,
  input: string,
};

export default {
  dueDateChange: (value: string): SurveyAction => {
    const now = new Date().getTime();
    let weeks;
    const userInputDate = Date.parse(value);
    const weeksLeft = (userInputDate - now) / (7 * 24 * 60 * 60 * 1000);
    if (weeksLeft < 14 || weeksLeft >= 18) weeks = null;
    else if (locale === 'en') {
      if (weeksLeft < 18) weeks = '22 weeks';
      if (weeksLeft < 17) weeks = '23 weeks';
      if (weeksLeft < 16) weeks = '24 weeks';
      if (weeksLeft < 15) weeks = '25 weeks';
    } else if (locale === 'es') {
      if (weeksLeft < 18) weeks = '22 semanas';
      if (weeksLeft < 17) weeks = '23 semanas';
      if (weeksLeft < 16) weeks = '24 semanas';
      if (weeksLeft < 15) weeks = '25 semanas';
    }

    return {
      type: 'DUE_DATE_CHANGE',
      value: weeks,
      input: value,
    };
  },
  weeksPregnantChange: (value: string): SurveyAction => ({
    type: 'WEEKS_PREGNANT_CHANGE',
    input: value,
    value,
  }),

  resetChances: (): { type: string } => ({ type: 'RESET_CHANCES' }),
};
